import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const IconRace = ({ selected }: TSelected) => ({
  boxSize: '4',
  bg: 'transparent',
  color: selected ? 'whiteAlpha.700' : 'beta.500',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'blackAlpha.600',
  color: selected ? 'whiteAlpha.700' : 'transparent',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
};

export const Divider: CSSObject = {
  display: 'none',
  bg: 'beta.500',
};

export const FlexRaceTypeWrapper: CSSObject = {
  mr: ['unset', 'auto'],
};

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  mt: '0.5',
  mb: '2.5',
  h: ['fit-content', '9'],
  position: 'inherit',
  flexWrap: ['wrap', null],
  justifyContent: 'space-between',
};

export const ButtonFilter = ({ selected }: TSelected) => ({
  bgGradient: selected
    ? 'linear(to-b, alpha.700, alpha.700)'
    : 'linear(to-b, alpha.500, alpha.600)',
  boxShadow: selected
    ? '0px 2px 3px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.07)'
    : '0px 4px 4px 0px var(--bc-colors-blackAlpha-300)',
  px: '2',
  h: '26px',
  borderRadius: 'md',
});
