import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const TextRaceConditions: CSSObject = {
  color: 'beta.800',
  fontFamily: 'body',
  fontWeight: 'normal',
  fontSize: 'xs',
  _after: {
    content: `""`,
    height: '4',
    width: '1px',
    bg: 'beta.300',
    pos: 'relative',
    mx: '1.5',
  },

  _last: {
    _after: {
      display: ['none', 'none', 'block'],
    },
  },

  span: {
    fontWeight: 'bold',
    fontFamily: 'body',
    mr: ['2px', '1'],
    color: 'beta.800',
  },

  b: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  borderRadius: 'base',
  mb: ['0', null],
  pt: '1',
  pb: ['1', '0', null, null],
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: ['center', 'column', 'center'],
  flexDir: ['column', 'row', 'row'],
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['2', '0'],
  mb: '2',
  alignItems: [null, null, 'flex-end'],
};

export const RaceHeaderWrapper: CSSObject = {
  '& .state-inprogress': {
    color: 'white',
  },
  ml: ['0', null],
};

export const RaceName: CSSObject = {
  fontSize: 'sm',
  color: 'beta.800',
  textTransform: 'capitalize',
  fontFamily: 'body',
  fontWeight: 'bold',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const RaceRunnerList: CSSObject = {
  p: '0',
  bg: 'transparent',
  mx: ['2', '0'],
  borderRadius: 'md',
  borderColor: 'beta.400',
  borderWidth: '0',
};

export const RaceDetailsHeading: CSSObject = {
  minWidth: 'max-content',
  textTransform: 'capitalize',
  color: 'beta.800',
  fontWeight: 'bold',
  m: '0',
  mx: '1',
  fontFamily: 'body',
};

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'tab',
    mr: '0',
    mb: '0',
    color: 'beta.500',
    h: '9',
    w: ['9', null, '9'],
    bg: 'linear-gradient(180deg, var(--bc-colors-beta-700) 0%,var(--bc-colors-beta-800) 100%)',
    boxShadow:
      '0px 3px 4px 0px var(--bc-colors-blackAlpha-300),0px 0px 0px 2px var(--bc-colors-whiteAlpha-400) inset,0px 0px 0px 1px var(--bc-colors-beta-900) inset',
    borderRadius: 'md',
    fontFamily: 'body',
    p: '1',
    fontSize: 'sm',
    fontWeight: 'black',
    borderBottom: '0',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="false"]': {
        color: 'beta.500',
      },
      '&[data-active="true"]': {
        '&&': {
          background: 'beta.900',
          boxShadow:
            '0px 2px 4px 0px var(--bc-colors-blackAlpha-500) inset, 0px 1px 0px 0px var(--bc-colors-whiteAlpha-500)',
          border: 'none',
          color: 'beta.500',
        },
      },
      '&[data-closed="true"]': {
        bg: 'transparent',
        border: '2px solid',
        borderColor: 'beta.300',
        color: 'beta.700',
        textShadow: 'unset',
        boxShadow: 'none',
      },
    },
  },
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontSize: 'xl',
  textTransform: 'capitalize',
  color: 'beta.800',
  fontFamily: 'accent',
};

export const ResultedStatusLabel: CSSObject = {
  bg: 'green.500',
  color: 'white',
  borderRadius: 'base',
  textTransform: 'uppercase',
  fontFamily: 'body',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
};

export const RaceStatusText: CSSObject = {
  color: 'orange.800',
  borderRadius: 'base',
  bg: 'orange.400',
  px: '2',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  textTransform: 'uppercase',
  fontFamily: 'body',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  mb: '0',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  width: '12',
  fontSize: '2xs',
  px: '1',
  svg: {
    color: 'black',
  },
};

export const RaceResultListWrapper: CSSObject = {
  gap: '2',
  mx: ['2', '0'],
  my: '2',
};

export const CountdownTimerContainer: CSSObject = {
  borderRadius: 'base',
  bg: 'none',
  '.chakra-badge.state-prior': {
    px: '2',
    py: '1',
    background: 'var(--bc-colors-alpha-500)',
    color: 'beta.500',
    boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  },
};

export const ButtonGroupWrapper: CSSObject = {
  ml: '0',
  px: ['2', '0'],
  gap: '1',
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '9',
  mr: '0',
  mb: '1',
  pr: '3',
  '& >div:last-child': {
    alignItems: ['end', 'center'],
    w: ['full', 'unset'],
  },
};

export const RaceRunnerHeadingContainer: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  textTransform: 'capitalize',
  color: 'beta.800',
  fontFamily: 'body',
  columnGap: '0',
  mx: '0',
  mb: '0',
  alignItems: 'center',
  '>div': {
    flexDirection: 'row-reverse',
  },
  '>p': {
    fontFamily: 'accent',
    fontWeight: 'normal',
    fontSize: 'md',
    p: '0',
    pl: '0',
    ml: '0',
    mb: ['0', '0', '1'],
    pt: '2',
  },
  '.chakra-switch__track': {
    background: 'beta.900',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200),0px 2px 2px 0px var(--bc-colors-blackAlpha-400) inset',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'beta.900',
      '& .chakra-switch__thumb': {
        background: 'beta.500',
      },
    },
  },
  svg: {
    color: 'beta.500',
  },
};

export const FlexHeaderLeft: CSSObject = {
  ml: ['0', null, null],
  mt: ['1', null],
};
