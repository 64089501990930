import { CSSObject } from '@chakra-ui/react';
import {
  RaceTableSchema,
  TLinkRaceItem,
  TTableHeading,
} from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'alpha.300', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'alpha.300'] },
};

export const FlexHeading: CSSObject = {
  pt: '3',
  pb: '2',
  pl: '3',
  ml: '0',
  color: 'alpha.700',
  top: ['12', '84px'],
  position: 'unset',
  borderBottom: 'none',
};

export const IconHeading: CSSObject = {
  mr: '2',
  color: 'beta.500',
  boxSize: '8',
  bg: 'linear-gradient(180deg, var(--bc-colors-beta-700) 0%,var(--bc-colors-beta-800) 100%)',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-accent-300),0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset,0px 0px 0px 1px var(--bc-colors-beta-900) inset',
  borderRadius: 'lg',
  p: '2',
};

export const BoxPageWrapper: CSSObject = {
  mx: '0',
  mb: ['2', '4'],
  borderWidth: '0',
  bg: 'beta.100',
  borderRadius: 'lg',
  px: '0',
  background: `linear-gradient(180deg, var(--bc-colors-beta-100) 0%, var(--bc-colors-beta-200) 100%)`,
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 0px 0px 4px var(--bc-colors-beta-300) inset',
  _last: {
    mb: '0',
  },
};

export const FlexRow: CSSObject = {
  mb: ['0', null, 'unset'],
  borderRadius: 'unset',
  background: 'var(--chakra-blackAlpha-100, var(--bc-colors-whiteAlpha-700))',
};

export const CenterTH = ({ isFirst }: TTableHeading) => ({
  bg: 'delta.600',
  color: 'white',
  fontFamily: 'body',
  border: '1px solid',
  borderColor: 'blackAlpha.300',
  borderWidth: '1px 0 0 1px',
  ...(isFirst && {
    bg: 'beta.200',
    px: '12',
    fontFamily: 'body',
    textTransform: 'uppercase',
    color: 'beta.800',
    borderWidth: '0',
    width: '110px',
  }),
});

export const BoxTableWrapper: CSSObject = {
  borderX: '0',
  borderColor: 'gamma.500',
  borderBottomRadius: 'lg',
  px: 'unset',
  ml: '1',
  mr: '1',
  mb: '1.5',
  borderRadius: '0',

  '.state-prior': {
    color: 'white',
  },

  '.state-ended': {
    bg: 'beta.500',
    color: 'alpha.800',
  },
};

export const TextRaceResults: CSSObject = {
  color: 'beta.500',
};

export const FlexHeadingRow: CSSObject = {
  bg: 'beta.500',
  color: 'gray.800',
  fontSize: '2xs',
  fontWeight: 'medium',
  h: '34px',
};

export const LinkRaceItem = ({ isActive }: TLinkRaceItem) => ({
  bg: [null, null, 'beta.100'],
  color: isActive ? [null, null, 'black'] : [null, null, 'gray.700'],
  borderColor: 'alpha.400',

  borderTop: [0, null, '0px'],
  borderTopColor: ['unset', null, 'blackAlpha.300'],
  borderLeft: ['unset', null, '1px'],
  borderLeftColor: ['unset', null, 'blackAlpha.300'],
  borderBottom: ['unset', null, '1px'],
  borderBottomColor: ['unset', null, 'blackAlpha.300'],
  borderRight: ['unset', null, 'none'],

  _hover: {
    bg: 'beta.100',
  },
});

export const TextHeading: CSSObject = {
  color: 'beta.800',
  fontFamily: 'subHeading',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const TextVenueName: CSSObject = {
  color: 'beta.800',
  fontFamily: 'body',
  fontWeight: 'extrabold',
};

export const TextVenueCountry: CSSObject = {
  color: 'beta.700',
  fontFamily: 'body',
  fontWeight: 'semibold',
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'row-reverse',
  columnGap: '2',
};

export const ButtonChevron: CSSObject = {
  borderRadius: 'sm',
  height: '7',
  width: '5',
  backgroundColor: 'none',
  '&&': {
    svg: {
      color: 'beta.500',
      height: '7',
      width: '5',
    },
  },
};

export const BoxVenueName: CSSObject = {
  backgroundColor: ['transparent', null, 'beta.100'],
  borderTop: ['0', null, '0'],
  borderBottom: [0, null, '1px'],
  borderBottomColor: ['unset', null, 'blackAlpha.300'],
  borderColor: 'blackAlpha.100',
  borderRight: [null, null, 'none'],
  w: ['100%', null, '110px'],
  borderLeft: 'none',
  position: 'relative',
  p: 0,
  '>div': {
    boxSize: 'full',
    backgroundColor: ['transparent', null, 'blackAlpha.100'],
    py: '1.5',
    pl: '1.5',
  },
};

export const BadgeRaceItem: CSSObject = {
  borderRadius: 'base',
};

export const BadgeRaceNumber: CSSObject = {
  borderRadius: 'base',
};
